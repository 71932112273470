@media screen and (min-width: 1400px) {
    .with-abs-position.with-abs-position-div-res{
        display: block!important;
    }
    .with-abs-position-div-none-res{
    display: none!important;
    }
  }

/* Desktop 1366 */

@media screen and (min-width: 1025px) and (max-width: 1600px) {
    .no-display-in-responsive{
        display: none;
    }
    
    .custom-modal-style .modal-body.with-left-shadow:after {
        width: calc(50% - 156px - 10% - -25px );
    }
    .max-w-lg{
        max-width: 150px;
    }
    .daily-schedule-main.daily-schedule-transform.res-width{
        width: 530px;
    }
    html{
        /* zoom:0.9; */
        height: 100%;
    }
    body {
        /* font-size: 13px; */
        padding-left: 75px;
        padding-right: 6px;
        padding-bottom: 85px;
        height: 100%;
    }
    .daily-schedule-main.with-ms-5px{
        margin-left: 5px !important;
    }
    thead.table-th-px-26px th.ressmall{
        padding: 12px 23px !important;
    }
    .kanban-view.task-list-accordian .accordion-item{
        width: 280px;
    }
    .ressmall > div{
        width: 50px;
    }
    .daily-schedule-main{
        width: 600px;
    }
    .after-login-header .left-part{
        width: 22%;
    }
    .task-modal-offcanvas-open .after-login-header .left-part{
        width: 38%;
    }
    body.task-modal-offcanvas-open .after-login-header .right-part{
        /* width: 22%; */
    }
    .w-30{
        width: 25% !important;
      }
    /* .comman-body-wrapper{
        height: calc(100vh - 70px);
    } */
    /* .global-search-main{
        max-width: 400px;
        width: 400px;
    } */
    .offcanvas.taskDetailModal.stand-alone{
        /* width: 45%; */
        width: calc(50% - 175px);
    right: 300px;
    }
    .offcanvas.taskDetailModal.stand-alone.docmodal{
        /* width: calc(50% + 280px); */
        right: 0;
        left: auto;
      }
    .comman-body-wrapper.modified-height{
        height: calc(100vh - 0px);
    }
    .comman-body-wrapper.modified-height.modified-res-default-height,
    .comman-body-wrapper.modified-height{
        height: calc(100vh - 80px);
    }
    /* .modal-open{
        padding-right: 10px !important;
    } */
    .c-top-calendar .calendar-date-wrapper a .date-details {
        padding-left: 8px;
    }

    /*Right-panel Open CSS*/
    .with-right-penel .list-view-toggle .task-list-card .task-bottom-part-full,
    .modal-open.with-left-penel .list-view-toggle .task-list-card .task-bottom-part-full {
        /* width: 100%;
        padding-right: 0px;
        padding-top: 6px; */
    }
.onboarding-wrapper-main .bottom-fix-button{
    right: 5px;
}
    .with-right-penel .list-view-toggle .task-list-card .task-bottom-part-full .task-right-static-options,
    .modal-open.with-left-penel .list-view-toggle .task-list-card .task-bottom-part-full .task-right-static-options {
        width: 100%;
        /* padding-right: 10px; */
    }

    .with-right-penel .list-view-toggle .task-list-card .task-bottom-part-full .task-right-static-options>div,
    .modal-open.with-left-penel .list-view-toggle .task-list-card .task-bottom-part-full .task-right-static-options>div {
        width: 100%;
    }

    .with-right-penel .list-view-toggle .list-view .main-calc-width-task,
    .modal-open.with-left-penel .list-view-toggle .list-view .main-calc-width-task {
        width: calc(100% - 40px);
        flex-grow: 0 !important;
    }

    .with-right-penel .list-view-toggle .list-view .task-bottom-part-full .w-185,
    .modal-open.with-left-penel .list-view-toggle .list-view .task-bottom-part-full .w-185 {
        flex-grow: 1;
    }

    .with-right-penel .list-view-toggle .task-list-card.list-view .task-left,
    .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view .task-left {
        /* flex-wrap: wrap; */
    }

    .with-right-penel .list-view-toggle .list-view .task-bottom-part-full .table-list-progress div,
    .modal-open.with-left-penel .list-view-toggle .list-view .task-bottom-part-full .table-list-progress div {
        width: 100%;
    }

    .with-right-penel .list-view-toggle .list-view .task-bottom-part-full .table-list-progress>div:first-child,
    .modal-open.with-left-penel .list-view-toggle .list-view .task-bottom-part-full .table-list-progress>div:first-child {
        display: none;
    }

    .with-right-penel .list-view-toggle .list-view .progress-wrapper-link,
    .modal-open.with-left-penel .list-view-toggle .list-view .progress-wrapper-link {
        width: 100%;
    }

    .with-right-penel .list-view-toggle .list-view .task-bottom-part-full .table-list-progress div.progress-text,
    .modal-open.with-left-penel .list-view-toggle .list-view .task-bottom-part-full .table-list-progress div.progress-text {
        width: auto;
        padding-left: 10px;
    }

    .with-right-penel .list-view-toggle .task-list-card.list-view .task-left-part-toggle,
    .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view .task-left-part-toggle {
        width: calc(100% - 50px) !important;
    }

    .with-right-penel .list-view-toggle .task-list-card.list-view .task-list-detail,
    .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view .task-list-detail {
        /* align-items: self-start; */
        align-items: center;
    }

    .with-right-penel .list-view-toggle .task-list-card.list-view .task-assign-section,
    .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view .task-assign-section {
        /* margin-left: -50px; */
        /* margin-top: 3px; */
        /* margin-top: 14px; */
    }

    .with-right-penel .list-view-toggle .list-view .main-calc-width .task-list-name,
    .modal-open.with-left-penel .list-view-toggle .list-view .main-calc-width .task-list-name {
        width: auto;
    }

    .with-right-penel .list-view-toggle .list-view .main-calc-width,
    .modal-open.with-left-penel .list-view-toggle .list-view .main-calc-width {
        width: auto;
        min-width: calc(100% - 220px);
    }

    .with-right-penel .list-view-toggle .task-list-card .task-list-name,
    .modal-open.with-left-penel .list-view-toggle .task-list-card .task-list-name {
        width: 100%;
    }

    .with-right-penel .list-view-toggle .list-view .main-calc-width,
    .modal-open.with-left-penel .list-view-toggle .list-view .main-calc-width {
        width: auto;
        min-width: calc(100% - 220px);
    }

    .with-right-penel .list-view-toggle .task-list-card .task-list-name,
    .modal-open.with-left-penel .list-view-toggle .task-list-card .task-list-name {
        width: 100%;
    }

   .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view .company-name-width {
    display: none;
   }
   .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view.schedule-card-main .task-assign-section {
    margin-left:-15px;
   }

    .modal-open.with-left-penel .list-view-toggle .list-view .w-185 {}

    /* Right Panel Open Css Over */
    .offcanvas.taskDetailModal.modal-full {
        /* width: calc(50% - 15px); */
        /* width: calc(100% - 75px); */
        width: calc(100% - 358px);
    }
    .with-right-penel .offcanvas.taskDetailModal {
        width: calc(50% - 175px);
        right: 300px;
    }
    .with-right-penel .offcanvas.taskDetailModal.stand-alone-comments {
        width: 300px;
        right: 0;
    }
    /*Kan ban view responsive*/
    .kanban-view .task-list-card.list-view{
        padding: 10px 5px;
    }
    .custom-modal-style.c-modal .modal-body.with-left-shadow:after{
        box-shadow: none;
    }
    .kanban-view.task-list-accordian .pill-wrapper{
        width: 200px;
        margin-left:-200px;
    }
    .kanban-view.task-list-accordian .accordion-button .left-task-header{
        min-width: auto;
    }
    .kanban-view .task-list-card .task-bottom-part-full{
        flex-wrap: wrap;
        padding-top: 10px;
    }
    .kanban-view .task-list-card .task-bottom-part-full .task-right-static-options{
        width: auto;
    }
    /* .kanban-view .task-list-card .task-bottom-part-full .task-right-static-options>div{
        flex-wrap: wrap;
        row-gap: 8px;
    } */
    .kanban-view .task-list-card .white-border-box{
        flex-grow: 1;
    }
    .kanban-view .list-view .w-185{
        /* display: none; */
        width: 70px;
    }
    /* .list-view-toggle .task-list-card .task-bottom-part-full{
    width:100%;
    padding-right:0px;
    padding-top: 6px;
  }
  .list-view-toggle .task-list-card .task-bottom-part-full .task-right-static-options{
    width:100%;
    padding-right: 10px;
  }
  .list-view-toggle .task-list-card .task-bottom-part-full .task-right-static-options > div{
    width:100%;
  }
  .list-view-toggle .list-view .main-calc-width-task{
    width: calc(100% - 40px);
    flex-grow: 0 !important;
  }
  .list-view-toggle .list-view .task-bottom-part-full .w-185{
    flex-grow: 1;
  }
  .list-view-toggle .task-list-card.list-view .task-left{
    flex-wrap:wrap;
  }
  .list-view-toggle .list-view .task-bottom-part-full .table-list-progress div{
    width:100%;
  }
  .list-view-toggle .list-view .task-bottom-part-full .table-list-progress > div:first-child{
    display:none;
  }
  .list-view-toggle .list-view .progress-wrapper-link{
    width:100%;
  }
  .list-view-toggle .list-view .task-bottom-part-full .table-list-progress div.progress-text{
    width: auto;
    padding-left: 10px;
  } */
    /* .task-left-part-toggle{
    width: calc(100% - 490px) !important;
  } */
    /* .list-view-toggle .task-list-card.list-view .task-left-part-toggle{
    width: calc(100% - 50px) !important;
  }
  .list-view-toggle .task-list-card.list-view .task-list-detail{
    align-items: self-start;
  }
  .list-view-toggle .task-list-card.list-view .task-assign-section{
    margin-left: -50px;
  }
  .list-view-toggle .list-view .main-calc-width .task-list-name{
    width: auto;
  }
  .list-view-toggle .list-view .main-calc-width{
    width: auto;
    min-width: calc(100% - 220px);
  }
  .list-view-toggle .task-list-card .task-list-name{
    width:100%;
  } */
    /* .with-left-penel .offcanvas.taskDetailModal{
    width: calc(50% - 180px);
  } */
    .offcanvas.taskDetailModal {
        width: calc(50% - 30px);
    }
    .task-modal-offcanvas-open.with-left-penel {
        width: calc(100% - (50% - 30px) - 5px);
    }
    .task-modal-offcanvas-open.with-left-penel .after-login-header {
        width: calc(100% - (50% - 30px) - 10px);
    }
    .task-modal-offcanvas-open.with-left-penel footer {
        width: calc(100% - (50% - 30px) - 15px);
    }
    .with-left-penel.task-modal-offcanvas-open {
        width: calc(100% - (50% - 165px) - 5px);
    }
    .with-left-penel.task-modal-offcanvas-open .after-login-header {
        width: calc(100% - (50% - 165px) - 10px);
    }
    .no-left-padding.with-left-penel.with-right-penel.task-modal-offcanvas-open .after-login-header{
        width: calc(100% - (50% - 180px) - 305px);
    }
    .no-left-padding.with-left-penel.with-right-penel.task-modal-offcanvas-open{
        width: calc(100% - (50% - 44px) - 176px);
    }
    .with-left-penel.task-modal-offcanvas-open footer {
        width: calc(100% - (50% - 165px) - 15px);
    }
    .modal-open.with-left-penel .list-view-toggle .list-view .w_172 {
        width: 55px;
    }

    .modal-open.with-left-penel .list-view-toggle .task-list-card.list-view .white-border-box {
        margin-right: 0px;
    }

    .modal-open.with-left-penel .offcanvas.taskDetailModal.modal-full {
        /* width: calc(50% - 150px); */
        /* width: calc(100% - 355px); */
    }


    .chat-main-box.with-bg .mension-box {
        width: calc(100% - 152px);
    }

    .offcanvas.offcanvas-end .tast-detail-tab-wrapper {
        height: calc(100% - 172px);
    }

    .task-detail-modal .task-setting-section .white-border-box {
        margin-bottom: 15px;
    }

    .task-detail-modal .task-setting-section .multi_image_wrapper {
        margin-bottom: 15px;
    }

    .task-detail-modal .task-setting-section .added-tag-list {
        padding-top: 0px;
    }

    .task-detail-modal .tast-detail-tab-wrapper {
        height: calc(100% - 240px);
        overflow: hidden;
    }

    .comman-main-box {
        /* padding-right: 0px; */
    }
    .comman-main-box.task-list-accordian{
        /* padding: 0px 5px; */
    }
    /* .list-view-toggle.custom-accordian-main .task-list-accordian-main .c-font.f-13 {
        font-size: 10px;
    } */
    .list-view-toggle .list-view .w-185 {
        /* display: none; */
        /* width: 100px; */
        
    }
    .c-font.f-10 {
        font-size: 8;
    }

    .badge {
        font-size: 9px;
        padding: 5px 5px;
    }

    .img-width-14 {
        width: 12px;
    }

    .h20w20 {
        height: 18px;
        width: 18px;
    }

    .img-width-18 {
        width: 16px;
    }

    .task-list-accordian.custom-accordian-main .accordian-header-box {
        top: -0.1px;
    }

    .comman_action_icon .action_icon {
        /* height: 22px;
        width: 22px; */
    }

    .c-dots.c-10 {
        height: 9px;
        width: 9px;
    }

    .priority-checkbox.span-13 .check_box .check_span_img {
        height: 12px;
        width: 12px;
    }

    .c-font.f-12 {
        /* font-size: 10px; */
    }
    .badge-custom.h18w18 {
        width: 16px;
        height: 16px;
      }
    .badge-custom {
        width: 20px;
        height: 20px;
    }

    .img-width-24 {
        width: 22px;
    }

    .h40w40 {
        width: 38px;
        height: 38px;
    }

    .custom-datepicker.w-90.datepicker-input .react-datepicker-wrapper .react-datepicker__input-container input {
        width: 75px;
        max-width: 115px;
        font-size: 11px;
    }

    .c-font.f-20 {
        font-size: 16px;
    }
    .c-font.f-16 {
        font-size: 14px;
    }

    /* .comman-tab-wrapper */
    .comman-tab-wrapper .tab-wrapper .tab-name {
        padding: 15px 7px;
    }
    .premium-wrapper-main {
        width:85% ;
    }
    .comman-data-table .rdt_TableCell{
        padding:5px 10px;
    }
    .comman-data-table .rdt_TableCol{
        padding:5px 10px;
    }
    .main-card-list {
        width: 350px;
    }
    .res-w-400.w-400{
        width: 300px;
    }
    .res-width-calc-400.width-calc-400{
        width: calc(100% - 300px);
    }
    footer .footer-links {
        /* transform: translateX(-42%); */
    }
    .task-modal-offcanvas-open footer .footer-links{
        /* transform: translateX(-58%); */
        /* width: 300px; */
    }
    .task-modal-offcanvas-open .footer-nav-linksnew:hover{
        width: 320px;
    }
    .task-modal-offcanvas-open footer .footer-links li a{
        padding: 5px 15px;
        width: 60px;
    }
    footer .footeranimation li .absolute_star{
        top: -10px;
    right: 4px;
    }
    .task-modal-offcanvas-open footer .footer-links li .hover-span{
        display: none;
    }
    .onboarding-wrapper-main{
        /* width: 90%; */
        /* width: 70%; */
    }
    .task-list-accordian .pill-wrapper{
        /* margin-right: 40px; */
    }
    .onboarding-wrapper-main.with-modified-width {
         /* width: 90%; */
         width: 1170px;
    }
    .with-left-penel .onboarding-wrapper-main.with-modified-width {
        width: 1000px;
   }
    .with-right-penel .offcanvas.taskDetailModal.modal-full-left-panel{
        width: calc(100% - 304px);
        right: 304px;
      }
     .checklist-with-content-part .task-checklist.task-checklist-icon-wrap > div {
        flex-wrap: wrap;
    }
    .checklist-with-content-part .task-checklist .left-checklist,
    .checklist-with-content-part .task-checklist .checklist-list{
        width: 100%;
        padding-bottom: 2px;
    }
    .checklist-with-content-part .task-checklist .right-checklist-action{
        padding-left: 38px;
    }
    .task-modal-offcanvas-open .custom-full-calendar .fc .fc-button-group .fc-button-primary {
        padding: 5px 8px;
    }
    .task-modal-offcanvas-open .custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-unread-button:after{
        top: -5px;
        width: 20px;
    }
    .task-modal-offcanvas-open .custom-full-calendar .fc .fc-button-group .fc-button-primary.fc-unread-button {
        padding-left: 22px;
        margin-right: 0px;
    }
    .task-modal-offcanvas-open .fc-button{
        font-size: 0.9em;
    }
    .task-modal-offcanvas-open .custom-full-calendar .fc .fc-toolbar-title{
        font-size: 10px;
        padding-left: 10px;
    }
    .calender-box-main .calender-action-icon {
        padding: 4px 6px;
        font-size: 10px;
        margin: 0 0;
    }
    .calender-box-main .calender-header-box {
        padding: 5px;
        font-size: 10px;
    }
    .calender-box-main .calender-box-text-wrapr .calender-profile .calender-box-profile .comman-image-box {
        width: 35px;
        height: 35px;
    }
    .calender-box-main .calender-box-text-wrapr .calender-profile .calender-name-box,
    .calender-box-main .calender-box-text-wrapr .progress-slider-with-action-icon .slider-progress {
        font-size: 10px;
    }
}

/*  Mobile Screen Responsive */
@media screen and (max-width: 767px) {

    /* Root tag */
    :root {
        --base-font: 13px;
    }

    html {
        height: 100%;
    }

    /*  body Tag */
    body {
        /* padding-top: 60px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 80px;
        height: auto; */
        overflow: hidden auto !important;
        padding: 15px !important;
        height: auto  !important;
    }

    /*  Comman Spacing Class */
    .h30w30 {
        height: 25px;
        width: 25px;
    }

    .comman-contnet-wrapper .p-15 {
        padding: 12px;
    }

    /*  Comman font class */
    .c-font.f-14 {
        font-size: 13px;
    }

    .c-font.f-16 {
        font-size: 15px;
    }

    /*  Comman Image class */
    .img-width-24 {
        width: 18px;
    }

    /*  Comman responsive Class */
    .res-d-none-imp {
        display: none !important;
    }

    .res-d-none {
        display: none !important;
    }

    .with_separator_10 {
        /* padding: 0px 8px; */
    }

    /* comman btn style */
    .btn {
        padding: 4px 10px;
    }

    /*  Footer Responsive */
    footer {
        padding-left: 20px;
        padding-right: 10px;
        padding-bottom: 20px;
        background: var(--base-body-color);
        z-index: 333;
    }
    .with-right-penel.task-modal-offcanvas-open footer{
        padding-right: 0px;
    }
    .footer-link-wrapper {
        width: 100%;
    }

    footer .footer-links {
        bottom: 10px;
        right: 10px;
        left: 10px;
        width: auto;
        transform: translateX(0%);
        height: 50px;
    }

    footer .footer-links li {
        flex-grow: 1;
    }

    footer .footer-links li a .c-font {
        display: none;
    }

    footer .footer-links li.mid-footer-link {
        width: 110px;
        flex-grow: 0;
    }

    footer .footer-contact-img {
        display: none;
    }

    .footer-add-btn {
        right: 10px;
        bottom: 65px;
        display: none;
    }

    /* Header Css */
    .after-login-header {
        padding: 5px 15px;
        height: auto;
    }

    .fixed-left-panel {
        transform: translateX(-80px);
    }

    .fixed-left-panel.left-show-res {
        transform: translateX(0px);
        height: calc(100% - 75px);
    }

    .fixed-left-panel.office-list.expanded {
        width: 85vw;
        max-width: 400px;
        height: calc(100% - 75px);
    }

    .fixed-left-panel .fixed-left-panel-wrapper {
        width: 85vw;
        max-width: 400px;
    }

    .fixed-left-panel.provider-list {
        width: calc(85vw - 70px);
        max-width: 330px;
        height: calc(100% - 75px);
    }

    .fixed-left-panel.provider-list .fixed-left-panel-wrapper {
        width: calc(85vw - 70px);
        max-width: 330px;
        height: calc(100% - 75px);
    }

    .office-detail .office-detail-name {
        display: block;
        width: 100%;
    }

    .after-login-header .link-part {}

    .after-login-header .center-part {
        display: none;
    }

    .after-login-header .left-part {
        /* width: 40%; */
        width: 35%;
    }

    .task-modal-offcanvas-open .after-login-header .left-part {
        width: 25%;
      }

    .after-login-header .hamburger-menu-link {
        display: block;
    }

    .after-login-header .h-user-part {
        padding-left: 0px;
        margin-left: 0px;
    }

    .after-login-header .h-user-part:after {
        display: none;
    }

    /*  Top Calendar  */
    .c-top-calendar {}

    .c-top-calendar .calendar-date-wrapper .cal-date-box {
        width: 33.33%;
        flex-direction: column;
    }

    .c-top-calendar .calendar-date-wrapper a .date-details {
        padding: 0;
    }

    .c-top-calendar .calendar-date-wrapper a .date-details .day-name {
        display: none;
    }

    .c-top-calendar .calendar-date-wrapper a .date-text {
        font-weight: var(--fw-medium);
    }
 
    /*  Task Box  */
    .task-list-card .task-drag-icon {
        margin-left: -5px;
    }

    .list-view .w-215 {
        width: 100%;
    }

    .list-view .w-260 {
        width: 100%;
    }

    .list-view .w_172 {
        width: 100%;
        justify-content: flex-end;
    }

    .list-view .w-140 {
        width: 140px;
    }

    .list-view .w-185 {
        width: 100%;
    }

    .list-view .main-calc-width {
        width: 100%;
    }

    .list-view .width100minus160 {
        width: 100%;
    }

    .list-view .width100minus25 {
        width: 100% !important;
    }

    .list-view-toggle .list-view .width100minus25 {
        width: calc(100% - 10px) !important;
    }

    .progress-slider .progress.w_100 {
        width: 50px;
    }

    .task-list-card.list-view .task-left {
        width: calc(100% - 160px);
        display: flex;
        flex-wrap: wrap;
    }

    .task-list-card .progress-wrapper-link {
        width: 100%;
    }

    .task-list-card.list-view .task-assign-section {
        margin-left: -140px;
    }

    .list-view .task-list-wrapper>.align-items-start {
        align-items: start !important;
    }

    .task-list-card.list-view .task-list-detail {
        width: calc(100% - 10px);
        padding-left: 7px;
        align-items: self-start;
    }

    .task-list-card.list-view .task-mid-part {
        padding: 10px 0px;
        border-bottom: var(--color-border) 1px solid;
    }

    .task-list-card.list-view .task-bottom-part {
        padding-top: 10px;
    }

    .task-list-card.list-view .progress-wrapper-minus {
        width: 100%;
    }

    /*task detail modal*/
    .task-detail-modal .offcanvas {
        max-width: 100%;
        overflow: hidden scroll;
    }

    .task-detail-modal .task-detail-left-main {
        width: 100%;
        padding-right: 0px;
        height: auto !important;
    }

    .custom-modal-style .modal-body {
        padding: 10px;
    }

    .task-detail-modal .task-detail-left-main .comman-tab-wrapper {
        overflow: scroll hidden;
        padding-bottom: 0px;
        margin-bottom: 15px;
    }

    .task-detail-modal .modal-header .modal-title .back-arrow-icon {
        display: block;
    }

    .task-detail-modal .right-modal-task-header .right-task-header {
        display: none;
    }

    .task-detail-modal .task-setting-section .white-border-box {
        margin-bottom: 10px;
    }

    .task-detail-modal .task-setting-section .multi_image_wrapper {
        margin-bottom: 10px;
    }

    .task-detail-modal .task-setting-section .added-tag-list {
        padding-top: 5px;
    }

    .task-detail-modal .tast-detail-tab-wrapper .task-checklist-wrapper {
        height: auto;
        overflow: hidden;
    }

    .task-detail-modal .task-checklist {
        padding: 10px 0px;
    }

    .task-detail-modal .task-detail-right-main {
        width: 100%;
        margin-top: 15px;
        background-color: transparent;
        border: 1px solid var(--color-border) !important;
    }

    .task-detail-modal .task-detail-left-main .react-datepicker-wrapper .react-datepicker__input-container input {
        max-width: 120px;
    }
    /* comman spacing */
    .res-padding-0 {
        padding: 0 !important;
    }

    .res-padding-top0 {
        padding-top: 0 !important;
    }

    .res-padding-left0 {
        padding-left: 0 !important;
    }

    .res-padding-bottom0 {
        padding-bottom: 0 !important;
    }

    .res-padding-right0 {
        padding-right: 0 !important;
    }

    /*font size*/
    .c-font.f-12 {
        font-size: 11px;
    }

    .c-font.f-13 {
        font-size: 12px;
    }

    .c-font.f-14 {
        font-size: var(--base-font);
    }

    .c-font.f-15 {
        font-size: 14px;
    }

    .c-font.f-16 {
        font-size: 15px;
    }

    .c-font.f-20 {
        font-size: 18px;
    }

    .c-font.f-22 {
        font-size: 16px;
    }

    .c-font.f-24 {
        font-size: 18px;
    }

    /* comman img width */
    .img-width-16 {
        width: 14px;
    }

    .img-width-8 {
        width: 6px;
    }

    .img-width-10 {
        width: 8px;
    }

    .img-width-14 {
        width: 12px;
    }

    .img-width-13 {
        width: 11px;
    }

    .img-width-18 {
        width: 16px;
    }

    .img-width-20 {
        width: 18px;
    }

    .img-width-24 {
        width: 22px;
    }

    /* my space  */
    .comman-body-wrapper {
        overflow: hidden auto;
        height: 100%;
    }

    .my-spaces-main .left-location-part {
        width: 100%;
        order: 2;
    }

    .my-spaces-main .right-spaces-part {
        width: 100%;
        order: 1;
    }

    .my-spaces-main .top-boxes-main {
        height: auto;
        padding-bottom: 15px;
    }

    .my-spaces-main .bottom-boxes-main {
        height: auto;
    }

    .my-spaces-main .big-box {
        width: 100%;
        margin-right: 0px;
        height: 50%;
    }

    .my-spaces-main .sub-box-wrapper {
        width: 100%;
        padding-top: 15px;
    }

    .my-spaces-main .right-spaces-part .bottom-detail-wrapper {
        padding-top: 15px;
    }

    .comman_action_icon .action_icon.h50w50 {
        width: var(--space-30);
        height: var(--space-30);
    }

    .my-spaces-main .right-spaces-part .h40w40 {
        width: var(--space-30);
        height: var(--space-30);
    }

    .my-spaces-main .top-boxes-main .sub-box-wrapper .small-box {
        margin: 15px 0px;
    }

    /* slider */
    .custom-slider .slider-main {
        height: 8px;
    }

    .custom-slider .slider-main .slider-circle {
        height: 12px;
    }

    .custom-slider .slider-main .slider-circle .slider-dots {
        height: 12px;
        width: 12px;
    }

    .custom-slider .slider-main .slider-bg {
        height: 8px;
    }

    .custom-slider .slider-main .slider-bg .active-sldier-bg {
        height: 8px;
    }

    /* comman page header responsive */
    .right-tabing-part .comment-input-box {
        display: none;
    }

    /* swal-overlay--show-modal */
    .swal-title {
        font-size: 18px;
    }

    .swal-text {
        font-size: 14px;
    }

    .swal-icon {
        width: 65px;
        height: 65px;
    }

    .swal-button {
        padding: 6px 18px;
    }

    .swal-icon--error__line {
        width: 33px;
        top: 32px;
    }

    /*calender*/
    .c-top-calendar .calendar-date-wrapper .cal-date-box {
        padding: 0px 10px;
    }

    .c-top-calendar .left-box.w65 {
        width: 55px;
    }

    .c-top-calendar .calendar-date-wrapper .cal-date-box {
        height: 50px;
    }

    .c-top-calendar .calendar-date-wrapper {
        padding: 0px 8px;
        width: calc(100% - 110px);
    }

    .slider_btn_left {
        left: 0;
    }

    .slider_btn_right {
        right: 0;
    }

    .slider_btn {
        width: 20px;
        height: 20px;
    }

    .slider_btn_left img {
        margin-left: 3px;
    }

    .slider_btn_right img {
        margin-left: -2px;
    }

    /* fullcalendar */
    .custom-full-calendar .fc .fc-button-group .fc-button-primary {
        padding: 5px 5px;
    }

    .custom-full-calendar .fc .fc-toolbar-title {
        font-size: 13px;
        text-align: center;
    }

    .fc .fc-button .fc-icon {
        font-size: 1.2rem;
    }

    .fc .fc-button {
        font-size: 0.9rem;
    }

    /* css by samir j ...........*/
    /* :- 26-07-22 */
    /* new class */
    .res-flex-column {
        flex-direction: column !important;
    }

    .res-flex-wrap-wrap {
        flex-wrap: wrap;
    }

    .res-flex-wrap-nowrap {
        flex-wrap: nowrap !important;
    }

    .res-width-100 {
        width: 100% !important;
    }

    .res-overflow-visible {
        overflow: visible !important;
        height: 100% !important;
    }

    .res-white-space {
        white-space: nowrap;
        overflow: auto;
    }

    .res-border-0 {
        border: 0;
    }

    .res-height-auto {
        height: auto !important;
    }

    /* regulare */
    .dataTables_wrapper .dataTables_paginate,
    .dataTables_wrapper .dataTables_info {
        white-space: nowrap;
        margin-bottom: 10px;
    }

    /* :- 28-07-22 */
    /* new class */
    .res-space-between {
        display: flex;
        justify-content: space-between;
    }

    .res-space-start {
        display: flex;
        justify-content:flex-start !important;
    }

    .res-flex-column {
        flex-direction: column;
    }

    .res-height-100 {
        height: 100% !important;
    }

    .res-overflow-auto {
        overflow: auto !important;
    }

    .res-ms-10px {
        margin-left: 10px;
    }

    .res-mt-10px {
        margin-top: 10px;
    }

    .res-me-10px {
        margin-right: 10px;
    }

    .res-mb-10px {
        margin-bottom: 10px;
    }

    .res-m-10px {
        margin: 10px;
    }

    /* regulare */
    .benchmark-right {
        width: calc(100% - 100px);
        padding-left: 10px;
    }

    .benchmark-left {
        width: 100px;
    }

    .benchmark_div_height input {
        margin: auto 6px;
    }

    .btn.btn-sm {
        padding: 4px 8px;
        font-size: 10px;
    }

    .comman-table thead th {
        padding: 10px 12px !important;
    }

    /* .......................*/
    /* Filter Responsive */
    .filter-header-responsive {
        display: block;
        border-bottom: 1px solid var(--color-white-05);
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .filter-wrapper {
        position: fixed;
        padding: 15px;
        bottom: 0px;
        background: var(--base-modal-header-color);
        z-index: 5;
    }

    .filter-wrapper .filter-select-box {
        max-width: 100%;
        margin: 5px 0px;
        width: 100%;
    }

    .filter-wrapper .filter-select-box.mr-10:last-child {
        margin-right: 0px;
    }

    .task-list-card .task-list-name {
        width: calc(100% - 70px);
    }

    .list-view .w-460 {
        width: 310px;
    }

    .list-view-toggle {
        width: 360px;
    }

    .list-view-toggle .task-list-card.list-view .task-mid-part .custom-datepicker.list-fix-width .react-datepicker__input-container input {
        width: 100%;
    }

    /* mywallet */
    .left-content-part.w-280 {
        width: 100%;
    }

    .right-content-part.width-calc-280 {
        width: 100%;
    }

    .comman-white-box {
        padding: 10px;
    }

    .offcanvas.offcanvas-end {
        top: 60px;
        right: 10px;
        width: 100%;
        max-width: calc(100% - 20px);
        height: calc(100vh - 50px - 90px);
        z-index: 8;
        left: 9px;
    }

    .task-checklist-wrapper {
        height: auto !important;
    }

    .dropdown-menu {
        width: 235px;
    }

    .dropdown-item {
        padding: 6px;
    }
    .Standalonemain .stand-alone-left-main{
        position: initial;
        width: 100%;
    }
    .Standalonemain .stand-alone-left-main .stand-alone-top{
        padding: 0;
    }
    .Standalonemain .stand-alone-left-main .logo-img .standalonelogo, .stand-alone-left-main .logo-img img{
        height: 40px;
    }
    .Standalonemain .stand-alone-left-main .stand-alone-top.standalonefooter{
        display: none;
    }
    .offcanvas.taskDetailModal.stand-alone{
        position: initial;
        width: 100%;
        max-width: 100%;
        border-right: 0;
        height: 100%;
        background-color: var(--base-modal-bg);
    }
    .offcanvas.taskDetailModal.stand-alone .task-name-input.w100minus40{
        width: 100%;
    }
    .offcanvas.taskDetailModal.stand-alone .task-name-input .form-control{
        width: 100%;
    }
    .offcanvas.taskDetailModal.stand-alone .task-setting-section.with-abs-position{
        /* position: relative;
        top: auto;
        right: auto; */
    }
    .offcanvas.taskDetailModal.stand-alone .task-setting-section.withbox-shadow:before{
        display: none;
    }
    .offcanvas.taskDetailModal.stand-alone .task-checklist .right-checklist-action .with-abs-position{
        position: relative;
        right: 0;
        padding-left: 0;
    }
    .offcanvas.taskDetailModal.stand-alone-comments{
        position: inherit;
        width: 100%;
        max-width: 100%;
        margin-top: 15px;
        border-right: 0;
    }
    .offcanvas.taskDetailModal.stand-alone-comments .comman-placeholder-wrapper  {
        padding: 10px 0px;
    }
    .offcanvas.taskDetailModal .checklist_textarea{
        height: auto !important;
    }
    .with-left-penel{
        padding-left: 15px !important;
    }
    .comman-body-wrapper.modified-height{
        height: 100%;
    }
    .task-checklist .left-checklist{
        width:100% !important;
    }
    .task-checklist .resw100{
        width: 100%;
    }
}
